<template>
    <header class="z-40 bg-primary" :class="{ dark: store.semidark && store.menu === 'horizontal' }">
        <div class="shadow-sm">
            <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
                <div class="horizontal-logo flex items-center justify-between lg:hidden ltr:mr-2 rtl:ml-2">
                    <NuxtLink to="/" class="main-logo flex shrink-0 items-center">
                        <img class="inline w-8 h-5 ltr:-ml-1 rtl:-mr-1" src="/assets/images/teamss_logo.png" alt="" />
                        <span class="hidden align-middle text-2xl font-semibold transition-all duration-300 dark:text-white-light md:inline ltr:ml-1.5 rtl:mr-1.5">SAPPA</span>
                    </NuxtLink>
                </div>
                <div class="hidden sm:block ltr:mr-2 rtl:ml-2">
                    <img class="inline w-15 h-10 ltr:-ml-1 rtl:-mr-1" src="/assets/images/teamss_logo.png" alt="" />
                </div>
                <div class="flex items-center flex-grow">
                    <div class="flex-1 flex items-center">
                        <div class="flex items-center bg-gray-100 p-2 rounded search-box">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 2a8 8 0 104.9 14.32l4.34 4.33a1 1 0 001.42-1.42l-4.33-4.34A8 8 0 0010 2zm-6 8a6 6 0 1111.95 1.24l.05.26.76.76L18 10a8 8 0 10-16 0z" clip-rule="evenodd" />
                            </svg>
                            <input type="text" placeholder="Search" class="bg-gray-100 outline-none ml-2 text-gray-700" />
                        </div>
                    </div>
                    <div class="flex nav-links">
                        <NuxtLink to="/" class="flex items-center p-2 text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7m-2 2v6a2 2 0 002 2h2a2 2 0 002-2v-6a2 2 0 012-2m-4 0V8a2 2 0 012-2h4" />
                            </svg>
                            <span class="ml-2">Home</span>
                        </NuxtLink>
                        <NuxtLink to="/network" class="flex items-center p-2 text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2-2 4 4m0 0l2-2 2 2m-6 4V6" />
                            </svg>
                            <span class="ml-2">My Network</span>
                        </NuxtLink>
                        <NuxtLink to="/b2c" class="flex items-center p-2 text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h10m-7 4h7" />
                            </svg>
                            <span class="ml-2">Property</span>
                        </NuxtLink>
                        <NuxtLink to="/mailbox" class="flex items-center p-2 text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                            </svg>
                            <span class="ml-2">Messaging</span>
                        </NuxtLink>
                        <NuxtLink to="/notifications" class="flex items-center p-2 text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3" />
                            </svg>
                            <span class="ml-2">Notifications</span>
                        </NuxtLink>
                        <!-- <NuxtLink to="/me" class="flex items-center p-2 text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 3h14M6 8h12M4 21h16M6 16h12m-6-4h0" />
                            </svg>
                            <span class="ml-2">Me</span>
                        </NuxtLink> -->
                    </div>
                </div>
                <div class="border-r border-gray-300 h-6 pr-4"></div>
                <div class="flex justify-right items-center pl-4">
                    <div class="ml-auto">
                        <div v-if="authStore.partnerId">
                            <NuxtLink to="/auth/cover-login" class="flex bg-primary text-white rounded-full px-8 py-2 items-center p-2 hover:text-white text-sm" @click="handleDashboardClick">Profile</NuxtLink>
                        </div>
                        <div v-else-if="!authStore.partnerName">
                            <NuxtLink class="flex bg-primary text-white rounded-full px-8 py-2 items-center p-2 hover:text-white text-sm" @click="handleLoginClick">LOGIN</NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, reactive, watch } from 'vue';
import appSetting from '@/app-setting';
import { useAuthStore } from '@/stores/authStore';
import { useRoute } from 'vue-router';
import { useAppStore } from '@/stores/index';

const store = useAppStore();
const authStore = useAuthStore();
const route = useRoute();
const search = ref(false);
const { setLocale } = useI18n();

// multi language
const changeLanguage = (item: any) => {
    appSetting.toggleLanguage(item, setLocale);
};

const currentFlag = computed(() => {
    return `/assets/images/flags/${store.locale?.toUpperCase()}.svg`;
});

const notifications = ref([
    {
        id: 1,
        profile: 'user-profile.jpeg',
        message: '<strong class="text-sm mr-1">John Doe</strong>invite you to <strong>Prototyping</strong>',
        time: '45 min ago',
    },
    {
        id: 2,
        profile: 'profile-34.jpeg',
        message: '<strong class="text-sm mr-1">Adam Nolan</strong>mentioned you to <strong>UX Basics</strong>',
        time: '9h Ago',
    },
    {
        id: 3,
        profile: 'profile-16.jpeg',
        message: '<strong class="text-sm mr-1">Anna Morgan</strong>Upload a file',
        time: '9h Ago',
    },
]);

const messages = ref([
    {
        id: 1,
        image: '<span class="grid place-content-center w-9 h-9 rounded-full bg-success-light dark:bg-success text-success dark:text-success-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg></span>',
        message: '<strong class="text-sm mr-1">Celine Dion</strong><span class="text-xs text-secondary dark:text-secondary">Ok..</span>',
        time: '1h Ago',
    },
    {
        id: 2,
        image: '<span class="grid place-content-center w-9 h-9 rounded-full bg-danger-light dark:bg-danger text-danger dark:text-danger-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></span>',
        message: '<strong class="text-sm mr-1">Elon Musk</strong><span class="text-xs text-secondary dark:text-secondary">Ok..</span>',
        time: '4h Ago',
    },
]);

onMounted(() => {
    // store.setLoading(false);
});
</script>

<style scoped>
header {
    background-color: #283e4a;
}

header .nav-links a {
    color: #008289;
}

header .nav-links a:hover {
    color: #00475b;
}

.search-box {
    border: 1px solid #dce6f1;
    border-radius: 6px;
}
</style>
